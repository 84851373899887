import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Mikan from "mikanjs"
import { DateTime } from "luxon"

import TagIcon from "components/TagIcon"

class CardPost extends React.Component {
  render() {
    return (
      <Link className="card-item is-angle-right" to={this.props.to}>
        <div className="inner is-padding-lg is-space-half">
          <div className="grid is-middle is-gap-2x">
            <div className="col">
              <TagIcon
                size="is-xl"
                icon_style={this.props.icon_style}
                icon_name={this.props.icon_name}
                icon_color={this.props.icon_color}
              />
            </div>
            <div className="col is-mobile-0 is-space-quarter">
              <h1
                className="text is-mikan-space-fix is-strong is-dark-2 is-sm"
                dangerouslySetInnerHTML={{
                  __html: Mikan(this.props.title)
                }}
              />
              <p className="text is-font-en-modern is-dark-3 is-space-right-half is-xs">
                {(() => {
                  if (this.props.status === "open") {
                    return (
                      <span className="badge is-plain is-strong is-open">
                        Open
                      </span>
                    )
                  } else if (this.props.status === "closed") {
                    return (
                      <span className="badge is-plain is-strong is-closed">
                        Closed
                      </span>
                    )
                  }
                })()}
                <span className="text is-inline-block">
                  <FontAwesomeIcon
                    className="icon"
                    icon={["far", "calendar-alt"]}
                  />
                  <span className="text">
                    {DateTime.fromISO(this.props.publishedAt, {
                      zone: "Asia/Tokyo"
                    }).toFormat("yyyy/MM/dd")}
                  </span>
                </span>
                <span className="text is-inline-block">
                  <FontAwesomeIcon className="icon" icon={["fas", "history"]} />
                  <span className="text">
                    {DateTime.fromISO(this.props.updatedAt, {
                      zone: "Asia/Tokyo"
                    }).toFormat("yyyy/MM/dd")}
                  </span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </Link>
    )
  }
}

export default CardPost
