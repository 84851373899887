import React from "react"
import PropTypes from "prop-types"
import Media from "react-media"
import StickyBox from "react-sticky-box"

import Aside from "components/Aside"

const GridAside = ({ children }) => (
  <div className="grid-system is-page-with-aside">
    <div className="col is-space-half">{children}</div>
    <div className="col">
      <Media query="(min-width: 768px)">
        {matches =>
          matches ? (
            <StickyBox offsetTop={8} offsetBottom={8}>
              <Aside />
            </StickyBox>
          ) : (
            <Aside />
          )
        }
      </Media>
    </div>
  </div>
)

GridAside.propTypes = {
  children: PropTypes.node.isRequired
}

export default GridAside
