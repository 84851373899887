import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class TagIcon extends React.Component {
  render() {
    return (
      <div
        className={"thumbnail-icon " + this.props.size}
        style={{ color: this.props.icon_color }}
      >
        <FontAwesomeIcon
          className="icon is-fit is-fit-fa-fix"
          icon={[this.props.icon_style, this.props.icon_name]}
        />
      </div>
    )
  }
}

export default TagIcon
