import React from "react"
import Helmet from "react-helmet"

//const pkg = require("root/package.json")
const pjt = require("root/project.json")

class Head extends React.Component {
  render() {
    return (
      <Helmet
        title={
          this.props.pageTitle
            ? this.props.pageTitle + " - " + pjt.site.title
            : pjt.site.title
        }
        htmlAttributes={{ lang: "ja" }}
        bodyAttributes={{
          class: "qrac-blog qrac-blog-" + this.props.id
        }}
      >
        <meta
          property="description"
          content={
            this.props.pageDescription
              ? this.props.pageDescription
              : pjt.site.description
          }
        />
        <meta
          property="format-detection"
          content="telephone=no, email=no, address=no"
        />
        <meta
          property="og:title"
          content={
            this.props.pageTitle
              ? this.props.pageTitle + " - " + pjt.site.title
              : pjt.site.title
          }
        />
        <meta
          property="og:description"
          content={
            this.props.pageDescription
              ? this.props.pageDescription
              : pjt.site.description
          }
        />
        <meta
          property="og:url"
          content={this.props.pageUrl ? this.props.pageUrl : pjt.site.url}
        />
        <meta
          property="og:image"
          content={(() => {
            if (this.props.id === "home") {
              return pjt.site.url + "/ogp.png"
            } else if (this.props.pageOgImage) {
              return this.props.pageOgImage
            } else {
              return pjt.site.url + "/ogp-min.png"
            }
          })()}
        />
        <meta property="og:site_name" content={pjt.site.title} />
        <meta
          property="og:type"
          content={this.props.id === "home" ? "website" : "article"}
        />
        <meta
          name="twitter:card"
          content={(() => {
            if (this.props.id === "home") {
              return "summary_large_image"
            } else if (this.props.pageOgImage) {
              return "summary_large_image"
            } else {
              return "summary"
            }
          })()}
        />
        <meta property="twitter:creator" content={"@" + pjt.site.twitter.id} />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="icon" href="/favicon.png" />
      </Helmet>
    )
  }
}

export default Head
