import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Head from "components/Head"
import GridAside from "components/GridAside"
import CardPost from "components/CardPost"
import AllTags from "components/widgets/AllTags"
import LastUpdated from "components/widgets/LastUpdated"

//const pkg = require("root/package.json")
const pjt = require("root/project.json")

const TagTemplate = ({ data }) => (
  <Layout>
    <Head
      id="tag"
      pageTitle={data.tag.data.name}
      pageUrl={pjt.site.url + "/tags/" + data.tag.data.slug + "/"}
    />
    <GridAside>
      <div className="card is-separate is-overflow-hidden">
        <div className="card-header">
          <div className="inner">
            <h1 className="heading is-dark-2 is-strong is-sm">
              {data.tag.data.name}：
              {(() => {
                const posts = data.tag.data.posts
                const result = posts.filter(function(value) {
                  return value.data.published === true
                })
                return result.length
              })()}
              件
            </h1>
          </div>
        </div>
        <ul className="card-list is-separate">
          {data.posts.edges.map((edge, i) =>
            edge.node.data.published === true ? (
              <li key={i}>
                <CardPost
                  to={`/posts/${edge.node.data.slug}/`}
                  title={edge.node.data.title}
                  icon_style={edge.node.data.tags[0].data.icon_style}
                  icon_name={edge.node.data.tags[0].data.icon_name}
                  icon_color={edge.node.data.tags[0].data.icon_color}
                  status={edge.node.data.status}
                  publishedAt={edge.node.data.publishedAt}
                  updatedAt={edge.node.data.updatedAt}
                />
              </li>
            ) : null
          )}
        </ul>
      </div>
      <AllTags />
      <LastUpdated />
    </GridAside>
  </Layout>
)

export default TagTemplate

export const query = graphql`
  query GetTag($slug: String!) {
    tag: airtable(data: { slug: { eq: $slug } }) {
      data {
        name
        slug
        icon_style
        icon_name
        posts {
          data {
            published
          }
        }
      }
    }
    posts: allAirtable(
      filter: {
        table: { eq: "posts" }
        data: { tags: { elemMatch: { data: { slug: { eq: $slug } } } } }
      }
      sort: { fields: [data___updatedAt], order: DESC }
    ) {
      edges {
        node {
          data {
            title
            published
            slug
            publishedAt
            updatedAt
            status
            tags {
              data {
                name
                slug
                icon_style
                icon_name
                icon_color
              }
            }
          }
        }
      }
    }
  }
`
