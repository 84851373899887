import React from "react"
import { StaticQuery, graphql } from "gatsby"

import profile from "images/qrac-profile-476.jpg"

const pjt = require("root/project.json")

const Aside = () => (
  <StaticQuery
    query={asideQuery}
    render={data => {
      return (
        <aside className="section is-aside is-space-half">
          <div className="card">
            <div className="inner is-padding-lg is-space">
              <h2 className="text is-strong is-font-en-modern is-uppercase is-dark-2 is-sm">
                Profile
              </h2>
              <div className="box is-center">
                <div className="author-image">
                  <img className="img" src={profile} alt={pjt.author.name} />
                </div>
              </div>
              <p className="texts is-xs">
                <span className="text is-strong">{pjt.author.name}</span>
                <span className="text">：{pjt.author.description}</span>
              </p>
            </div>
          </div>

          <div className="card">
            <div className="inner is-padding-lg is-space">
              <h2 className="text is-strong is-font-en-modern is-uppercase is-dark-2 is-sm">
                Works
              </h2>
              <ul className="list is-circle is-external-link-area is-sm">
                {pjt.works.map(work => (
                  <li className="item" key={work.name}>
                    <a
                      className="text is-link-reverse is-primary"
                      href={work.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {work.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="card">
            <div className="inner is-padding-lg is-space">
              <h2 className="text is-strong is-font-en-modern is-uppercase is-dark-2 is-sm">
                Accounts
              </h2>
              <ul className="list is-circle is-external-link-area is-sm">
                {pjt.accounts.map(account => (
                  <li className="item" key={account.name}>
                    <a
                      className="text is-link-reverse is-primary"
                      href={account.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {account.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="card">
            <div className="inner is-padding-lg is-space">
              <h2 className="text is-strong is-font-en-modern is-uppercase is-dark-2 is-sm">
                Links
              </h2>
              <ul className="list is-circle is-external-link-area is-sm">
                {pjt.links.map(link => (
                  <li className="item" key={link.name}>
                    <a
                      className="text is-link-reverse is-primary"
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </aside>
      )
    }}
  />
)

export default Aside

const asideQuery = graphql`
  query AsideQuery {
    posts: allAirtable(filter: { table: { eq: "posts" } }) {
      edges {
        node {
          data {
            published
            slug
          }
        }
      }
    }
    tags: allAirtable(
      filter: { table: { eq: "tags" }, data: { published: { eq: true } } }
    ) {
      edges {
        node {
          data {
            name
            published
            slug
            icon_style
            icon_name
            posts {
              data {
                published
              }
            }
          }
        }
      }
    }
  }
`
