import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

const AllTags = () => (
  <StaticQuery
    query={allTagsQuery}
    render={data => {
      return (
        <div className="card is-separate is-overflow-hidden">
          <div className="card-header">
            <div className="inner">
              <h2 className="heading is-dark-2 is-strong is-sm">All Tags</h2>
            </div>
          </div>
          <div className="card-list">
            <div className="inner is-padding-lg is-space">
              <div className="btns is-tagcloud is-xs">
                {data.tags.edges.map((edge, i) => (
                  <Link
                    to={"/tags/" + edge.node.data.slug + "/"}
                    className="btn is-outline"
                    key={i}
                  >
                    <span className="text">{edge.node.data.name}</span>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      )
    }}
  />
)

export default AllTags

const allTagsQuery = graphql`
  query AllTagsQuery {
    tags: allAirtable(
      filter: { table: { eq: "tags" }, data: { published: { eq: true } } }
    ) {
      edges {
        node {
          data {
            name
            published
            pickup
            slug
            icon_style
            icon_name
            icon_color
            content {
              childMarkdownRemark {
                excerpt(pruneLength: 50, truncate: true)
              }
            }
            posts {
              data {
                published
              }
            }
          }
        }
      }
    }
  }
`
